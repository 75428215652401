import { IComponent, IComponentProps, msdyn365Commerce } from '@msdyn365-commerce/core';
import classnames from 'classnames';
import React from 'react';

export interface ISearchResultsSelectVariantComponentProps extends IComponentProps<{}> {
    className?: string;
    selectVariantText?: string;
    navigationUrl?: string;
}

export interface ISearchResultsSelectVariantComponent extends IComponent<ISearchResultsSelectVariantComponentProps> {
    onClick(): (event: React.MouseEvent<HTMLElement>, props: ISearchResultsSelectVariantComponentProps) => void;
}

const SearchResultsSelectVariant: React.FC<ISearchResultsSelectVariantComponentProps> = (
    props: ISearchResultsSelectVariantComponentProps
) => {
    const onClickHandler = (event: React.MouseEvent<HTMLElement>) => {
        return SearchResultsSelectVariantComponentActions.onClick(event, props);
    };
    return (
        <button className={classnames('ms-button', props.className)} aria-label={props.selectVariantText} onClick={onClickHandler}>
            {props.selectVariantText}
        </button>
    );
};

const onClick = async (_event: React.MouseEvent<HTMLElement>, props: ISearchResultsSelectVariantComponentProps): Promise<void> => {
    if (props.navigationUrl) {
        window.location.assign(props.navigationUrl);
    }
};

const SearchResultsSelectVariantComponentActions = {
    onClick: onClick
};

// prettier-ignore
// @ts-ignore
export const SearchResultsSelectVariantComponent: React.FunctionComponent<ISearchResultsSelectVariantComponentProps> = msdyn365Commerce.createComponent<ISearchResultsSelectVariantComponent>('SearchResultsSelectVariant', { component: SearchResultsSelectVariant, ...SearchResultsSelectVariantComponentActions });
