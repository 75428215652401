import { getProductPageUrlSync } from '@msdyn365-commerce-modules/retail-actions';
import { Alert, format } from '@msdyn365-commerce-modules/utilities';
import { PriceComponent, RatingComponent } from '@msdyn365-commerce/components';
import {
    IComponent,
    IComponentProps,
    ICoreContext,
    IGridSettings,
    IImageData,
    IImageSettings,
    Image,
    msdyn365Commerce
} from '@msdyn365-commerce/core';
import { ProductPrice, ProductSearchResult } from '@msdyn365-commerce/retail-proxy';
import React from 'react';

export interface IProductComponentProps extends IComponentProps<{ product?: ProductSearchResult }> {
    className?: string;
    imageSettings?: IImageSettings;
    savingsText?: string;
    freePriceText?: string;
    originalPriceText?: string;
    currentPriceText?: string;
    ratingAriaLabel?: string;
}

export interface IProductComponent extends IComponent<IProductComponentProps> {}

const PriceComponentActions = {};

const ProductCard: React.FC<IProductComponentProps> = ({
    data,
    context,
    imageSettings,
    savingsText,
    freePriceText,
    originalPriceText,
    currentPriceText,
    ratingAriaLabel,
    typeName,
    id
}) => {
    const product = data.product;
    if (!product) {
        return null;
    }

    const productUrl = getProductPageUrlSync(product.Name || '', product.RecordId, context && context.actionContext, undefined);

    // Construct telemetry attribute to render
    const attribute =
        context &&
        context.telemetry &&
        context.telemetry.setTelemetryAttribute &&
        context.telemetry.setTelemetryAttribute(product.RecordId.toString(), {
            pid: product.RecordId,
            pname: product.Name,
            mname: id
        });
    return (
        <div>
            <a
                href={productUrl}
                aria-label={renderLabel(
                    product.Name,
                    context.cultureFormatter.formatCurrency(product.Price),
                    product.AverageRating,
                    ratingAriaLabel
                )}
                className='msc-product'
                {...attribute}
            >
                <div className='msc-product__image'>
                    {renderProductPlacementImage(imageSettings, context.request.gridSettings, product.PrimaryImageUrl, product.Name)}
                </div>
                <div className='msc-product__details'>
                    <h4 className='msc-product__title'>{product.Name}</h4>

                    {renderPrice(
                        context,
                        typeName,
                        id,
                        product.BasePrice,
                        product.Price,
                        savingsText,
                        freePriceText,
                        originalPriceText,
                        currentPriceText
                    )}
                    {renderDescription(product.Description)}
                    {renderRating(context, typeName, id, product.AverageRating, product.TotalRatings, ratingAriaLabel)}
                </div>
            </a>
            <div className='ms-product-add-to-cart-controls'>
                {supportsQuickAdd(product) && renderQuickAddButton(context, product, typeName, id)}
                {!supportsQuickAdd(product) && renderSelectVariant(context, typeName, id, productUrl)}
            </div>
        </div>
    );
};

function renderLabel(name?: string, price?: string, rating?: number, ratingAriaLabel?: string): string {
    let ratingLabel = '';

    name = name || '';
    price = price || '';

    if (rating) {
        const roundedRating = rating.toFixed(2);
        ratingLabel = format(ratingAriaLabel || '', roundedRating, '5');
    }

    return `${name} ${price} ${ratingLabel}`;
}

function renderDescription(description?: string): JSX.Element | null {
    return <p className='msc-product__text'>{description}</p>;
}

function renderRating(
    context: ICoreContext,
    typeName: string,
    id: string,
    avgRating?: number,
    totalRatings?: number,
    ariaLabel?: string
): JSX.Element | null {
    if (!avgRating) {
        avgRating = 0;
    }

    const numRatings = (totalRatings && totalRatings.toString()) || undefined;

    return (
        <RatingComponent
            context={context}
            id={id}
            typeName={typeName}
            avgRating={avgRating}
            ratingCount={numRatings}
            readOnly={true}
            ariaLabel={ariaLabel || ''}
            data={{}}
        />
    );
}

function renderPrice(
    context: ICoreContext,
    typeName: string,
    id: string,
    basePrice?: number,
    adjustedPrice?: number,
    savingsText?: string,
    freePriceText?: string,
    originalPriceText?: string,
    currentPriceText?: string
): JSX.Element | null {
    const price: ProductPrice = {
        BasePrice: basePrice,
        AdjustedPrice: adjustedPrice,
        CustomerContextualPrice: adjustedPrice
    };

    return (
        <PriceComponent
            context={context}
            id={id}
            typeName={typeName}
            data={{ price: price }}
            savingsText={savingsText}
            freePriceText={freePriceText}
            originalPriceText={originalPriceText}
        />
    );
}

function renderProductPlacementImage(
    imageSettings?: IImageSettings,
    gridSettings?: IGridSettings,
    imageUrl?: string,
    altText?: string
): JSX.Element | null {
    if (!imageUrl || !gridSettings || !imageSettings) {
        return null;
    }
    const img: IImageData = {
        src: imageUrl,
        altText: altText ? altText : ''
    };
    const imageProps = {
        gridSettings: gridSettings,
        imageSettings: imageSettings
    };
    return <Image {...img} {...imageProps} loadFailureBehavior='empty' />;
}

function renderQuickAddButton(context: ICoreContext, product: ProductSearchResult, typeName: string, id: string): JSX.Element | null {
    // @ts-ignore-start
    /* tslint:disable */
    const params = new URL(document.location.toString()).searchParams;
    /* tslint:enable */
    // @ts-ignore-end
    // const params = new URL(document.location.toString()).searchParams;
    const refiners = params.get('refiners');

    return (
        <div className='ms-product-add-to-cart'>
            <button className='msc-product__add-to-cart-button' onClick={() => addToCart(product)}>
                Add to cart
            </button>
        </div>
    );
}

function renderSelectVariant(context: ICoreContext, typeName: string, id: string, navigationUrl: string): JSX.Element | null {
    return (
        <div className='ms-product-add-to-cart'>
            <a href={navigationUrl} className='msc-product__select-variant-button'>
                Select variant
            </a>
        </div>
    );
}

function supportsQuickAdd(product: ProductSearchResult): boolean {
    let res: boolean = false;

    if (product && product.ExtensionProperties) {
        product.ExtensionProperties.forEach(element => {
            if (element && element.Key === 'IsProductMaster') {
                if (element.Value && element.Value.BooleanValue !== undefined) {
                    res = !element.Value.BooleanValue;
                }
            }
        });
    }
    return res;
}

function addToCart(product: ProductSearchResult): void {
    // Logic to add product to cart
    console.log('Adding product to cart:', product);
}

function renderError(message: string): JSX.Element | null {
    const header: string = 'Failed to add item to bag';
    return (
        <Alert color='danger' assertive={true} aria-label={header}>
            <div className='msc-alert__header' aria-hidden='true'>
                <span className='msi-exclamation-triangle' />
                <span>{header}</span>
            </div>
            <div>{message}</div>
        </Alert>
    );
}
renderError('error');

// @ts-ignore
export const ProductComponent: React.FunctionComponent<IProductComponentProps> = msdyn365Commerce.createComponentOverride<
    IProductComponent
>('Product', { component: ProductCard, ...PriceComponentActions });

export default ProductComponent;
